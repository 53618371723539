<template>
  <div class="login__wrapper d-flex justify-space-around align-center">
    <v-card elevation="2" width="600" class="login__card">
      <v-form v-model="valid" class="d-flex flex-column pt-10 pb-10 pr-5 pl-5">
        <h1 class="align-self-center"> {{ $t('login') }}</h1>
        <v-text-field color="#757575" :label="$t('user name')" type="email" :rules="[rules.required]"
          prepend-icon="mdi-account" v-model="userData.username" @keyup.enter="submit"></v-text-field>
        <v-text-field color="#757575" :label="$t('password')" :append-icon="visiblePassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="visiblePassword ? 'text' : 'password'" :rules="[rules.required, rules.min]"
          @click:append="visiblePassword = !visiblePassword" prepend-icon="mdi-lock" v-model="userData.password"
          @keyup.enter="submit"></v-text-field>
        <v-autocomplete v-model="userData.termId" :rules="[rules.required]" :loading="termsLoading" :items="terms"
          :label="$t('term')" prepend-icon="mdi-counter" item-text="name" item-value="id"
          color="#757575"></v-autocomplete>
        <v-tooltip right :disabled="valid" color="red">
          <template v-slot:activator="{ on }">
            <div v-on="on" class="align-self-center">
              <v-btn color="success" :loading="btnLoading" class="login__btn" @click="submit" :disabled="!valid">{{
    $t('submit')
}}</v-btn>
            </div>
          </template>
          <span>{{ $t('input requird information') }}</span>
        </v-tooltip>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      valid: false,
      btnLoading: false,
      userData: {
        username: null,
        password: null,
        termId: null,
      },
      visiblePassword: false,
      terms: [],
      termsLoading: false,
      rules: {
        required: (value) => !!value || this.$t('requird'),
        min: (v) => v?.length >= 8 || this.$t('password should be at least 8 char'),
      },
    };
  },
  async created() {
    this.$store.commit("SET_DARK_MODE", true);
    localStorage.setItem('theme', 'dark');
    document.documentElement.setAttribute('data-theme', 'dark');
    try {
      this.termsLoading = true;
      const response = await axios.get("terms", {
        params: {
          size: 100000,
        },
      });
      this.terms = response.data.terms;
    } catch (err) {
    } finally {
      this.termsLoading = false;
    }
  },
  methods: {
    submit() {
      if (!this.valid) return;
      if (
        !this.userData.username ||
        !this.userData.password ||
        !this.userData.termId
      )
        return;
      this.btnLoading = true;
      this.$store.dispatch("userLogin", this.userData).finally((_) => {
        this.btnLoading = false;
      });
    },
  },
};
</script>

<style>
.login__wrapper {
  height: 100%;
  background: linear-gradient(to right, #bdc3c7, #6886a7);
  position: relative;
}

.login__card {
  animation: slideDown linear 0.5s;
}

.login__btn {
  transition: all ease 0.5s;
}
</style>